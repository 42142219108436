import React from 'react'
import "./connect.css"
import {motion} from "framer-motion"
import SentenceCycler from "../sentencecycler/SentenceCycler";

export const Connect = () => {
  return (
    <div className="container connect">
      <div className="title">
        <h1>Let's Connect!</h1>
      </div>
      <div className="content center">
        <motion.div className="box" whileHover={{y: -15, scale: 1.05}}>
          <div className="contact-methods">
            <div className="method">
              <h2>Email</h2>
              <p><a href="mailto:andy@andylabs.org">andy@andylabs.org</a></p>
            </div>
            <div className="method">
              <h2>LinkedIn</h2>
              <p><a href="https://www.linkedin.com/in/andyanderson8/" target="_blank" rel="noopener noreferrer">AndyAnderson8</a></p>
            </div>
            <div className="method">
              <h2>GitHub</h2>
              <p><a href="https://github.com/AndyAnderson8" target="_blank" rel="noopener noreferrer">AndyAnderson8</a></p>
            </div>
            <div className="method">
              <h2>Instagram</h2>
              <p><a href="https://www.instagram.com/sir.andy/" target="_blank" rel="noopener noreferrer">sir.andy</a></p>
            </div>
          </div>
          <div className="globe-animation">
            <SentenceCycler />
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Connect