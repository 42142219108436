import React from 'react'
import "./projects.css"
import Tilecard from "../tilecard/Tilecard"
import mlsd from "../../assets/MachineLearningSpamDetector.png"
import asa from "../../assets/AppointmentSchedulingApp.png"
import bps from "../../assets/BrickPlanetSniper.png"
import ds from "../../assets/DaytradingSimulator.png"
import urb from "../../assets/UWRegistrationBot.png"
import cos from "../../assets/CovidOriginStudy.png"
import {BsGithub} from 'react-icons/bs';

export const Projects = () => {
  return (
    <div className="container alt">
      <div className="title">
        <h1>My Projects</h1>
      </div>
      <div className="projects content">
        <div className="grid">
          <Tilecard 
            image={mlsd}
            name="ML Spam Detection" 
            description="A machine learning powered spam comment detection tool utilizing a logistic regression model in Python. Features interactive Flask-based web demo and data visualizations powered by Matplotlib."
            github="https://github.com/AndyAnderson8/Spam-Comment-Detector" 
            link="https://techsmash.pythonanywhere.com/"
            tag1="Python"
            tag2="Flask"
            tag3="Machine-Learning"
          />
          <Tilecard 
            image={asa}
            name="Appt Scheduling App" 
            description="Java CRUD application for appointment scheduling and management. Features user authentication, appointment notifications, time zone conversions, and customer record management."
            github="https://github.com/AndyAnderson8/Appointment-Scheduling-Application" 
            link="https://github.com/AndyAnderson8/Appointment-Scheduling-Application/archive/refs/heads/main.zip"
            tag1="Java"
            tag2="MySQL"
            tag3="User-Interface"
          />
          <Tilecard 
            image={bps}
            name="BrickPlanet Sniper" 
            description="A robust Python-powered application designed to swiftly detect and purchase newly released rare items on the massively multiplayer online game BrickPlanet. Currently monitized with 30+ active users."
            github="https://github.com/AndyAnderson8/BrickPlanet-Sniper" 
            link="https://github.com/AndyAnderson8/BrickPlanet-Sniper/archive/refs/heads/main.zip"
            tag1="Python"
            tag2="Automation"
            tag3="HTTP-Methods"
          />
          <Tilecard 
            image={ds}
            name="Daytrading Simulator" 
            description="A Roblox stock trading game utilizing real-time stock pricing data. Allows users to virtually buy and sell securities in game and compete with other players to be the best trader."
            github="https://github.com/AndyAnderson8/DaytradingSimulator" 
            link="https://www.roblox.com/games/6910260668/Daytrading-Simulator"
            tag1="Lua"
            tag2="Roblox"
            tag3="REST-API"
          />
          <Tilecard 
            image={urb}
            name="UW Registration Bot" 
            description="Automated tool to monitor the availability of specified classes at UW. Automatically registers for all available classes once registration begins, and continuously monitors closed classes until a seat opens."
            github="https://github.com/AndyAnderson8/Miscellaneous-Scripts/blob/main/ClassRegisterBot.py" 
            link="https://github.com/AndyAnderson8/Miscellaneous-Scripts/blob/main/ClassRegisterBot.py"
            tag1="Python"
            tag2="Selenium"
            tag3="Automation"
          />
          <Tilecard 
            image={cos}
            name="COVID-19 Origin Study" 
            description="Independent bioinformatics project on the origin of SARS-CoV-2 utilizing the Levenshtein distance algorithm. Uses real-world genome data of various animal coronaviruses as points of comparison."
            github="https://github.com/AndyAnderson8/SARS-CoV-2-Origin-Study" 
            link="https://github.com/AndyAnderson8/SARS-CoV-2-Origin-Study/blob/main/Report.pdf"
            tag1="Python"
            tag2="Biology"
            tag3="Data-Analytics"
          />
        </div>
        <a href="https://github.com/AndyAnderson8" target="_blank" rel="noopener noreferrer" className="more btn">
          <h2><BsGithub className="btn-icon" size={32}/> &nbsp; View More</h2>
        </a>
      </div>
    </div>
  )
}

export default Projects