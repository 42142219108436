import React from 'react'
import "./header.css"
import {BsLinkedin} from 'react-icons/bs';
import {BsGithub} from 'react-icons/bs';
import {MdEmail} from 'react-icons/md';
import logo from "../../assets/Logo.png"
import name from "../../assets/Name.png"

export const Header = () => {
  return (
    <div className="container alt center">
      <div className="header bar">
        <a href="" className="logo">
          <img className="logo-icon" src={logo} alt="Logo" height="30px"/>
          <img className="logo-name" src={name} alt="Logo" height="30 px"/>
        </a>
        <div className="links">
          <a href="../Andy-Anderson-CV.pdf"  target="_blank" rel="noopener noreferrer"><div className="btn">View CV</div></a>
          <div className="social-icons">
            <a href="https://linkedin.com/in/AndyAnderson8" target="_blank" rel="noopener noreferrer"><BsLinkedin size={24}/></a>
            <a href="https://github.com/AndyAnderson8" target="_blank" rel="noopener noreferrer"><BsGithub size={24}/></a>
            <a href="mailto:andy@andylabs.org" target="_blank" rel="noopener noreferrer"><MdEmail size={24}/></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header