import React, { Component } from "react";
import "./sentencecycler.css"; // Import the CSS file

class SentenceCycler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sentences: [
        "               11113              \n\
          111111 1 8 686          \n\
        1111111 6 88888886        \n\
        1111111118888886688       \n\
      11111111188 11618 1166      \n\
     11111111118 1   1 188868     \n\
    1111111111 888881111118888    \n\
   1111111111188888888888888888   \n\
  111111111118888888888888 88186  \n\
  111111111188888888888888818831  \n\
 111111111118888888888888888388 3 \n\
  11111111118888888888888888 8811 \n\
 1111111111 88888888888888888  11 \n\
 11111111111888888888888888888611 \n\
8 11111111111888888888888888888111\n\
88 11111111111 1111 88888888888111\n\
8881111111111111111188888888881111\n\
8886111111111111111188888881861111\n\
88888 1111111111111118888838811111\n\
8888881111111111111116888868811111\n\
 88888111111111111111 888886811111\n\
 8888 111111111111111888888381611 \n\
 888861111111111111118888888 3111 \n\
 18886111111111111111888888118111 \n\
  888111111111111111138888811 11  \n\
   881111111111111111 8888111111  \n\
   8811111111111111111888111111   \n\
    81111111111111111183111111    \n\
     111111111111111111111111     \n\
      1111111111111111111111      \n\
       11111111111111111111       \n\
        111111111111111111        \n\
          11111111111111          \n\
             11111                ",

"              11136 1             \n\
          11111 31388886          \n\
        111136188888888886        \n\
       11111168868883 88688       \n\
      111118881   88 618 888      \n\
     111111861 311116888 1888     \n\
    111111188888 11111 8888888    \n\
   1111111888888888888888868888   \n\
  1111111888888888888886886 8886  \n\
  111111888888888888888 88883168  \n\
 11111118888888888888888188881163 \n\
 111111388888888888888886 88 1118 \n\
 11111188888888888888888886111118 \n\
11111111888888888888888888381111 1\n\
1111111188888888888888888888111111\n\
611111111 1111 888888888888 111111\n\
81111111111111188888888888 1111111\n\
8111111111111118888888868811111111\n\
88 1111111111111888888888111111111\n\
8881111111111111688888688111111111\n\
 861111111111111 88888868111111111\n\
 83111111111111188888883811611111 \n\
 88111111111111188888888818111111 \n\
 68111111111111188888881118111111 \n\
  311111111111111888888113 11111  \n\
  611111111111111888881111111111  \n\
   11111111111111 8888111111111   \n\
    1111111111111188 111111111    \n\
     111111111111111111111111     \n\
      1111111111111111111111      \n\
       11111111111111111111       \n\
        111111111111111111        \n\
          11111111111111          \n\
             11111                ",

"             11 831               \n\
          11138168388886          \n\
        113688888888888886        \n\
       1118886888 188186888       \n\
      11 6811638366183 88888      \n\
     1118 1111116668881888888     \n\
    1111888611 111 88888888888    \n\
   1111888888888886888 88888888   \n\
  1111888888888888188881 8888886  \n\
  11188888888888888188888 118888  \n\
 1116888888888888881 8888 1118816 \n\
 11188888888888888881888111118816 \n\
 11188888888888888888611111111811 \n\
111188888888888888888868111111811 \n\
11116888888888888888888 111111111 \n\
11111 111 88888888888881111111111 \n\
111111111188888888888811111111111 \n\
1111111111888888868881111111111111\n\
3111111111188888888811111111111111\n\
8111111111188888888811111111111111\n\
 1111111111 888888 831111111111111\n\
 1111111111688888838311 111111111 \n\
 11111111118888888881181111111111 \n\
  1111111111888888811 81111111111 \n\
  11111111118888888118 111111111  \n\
  1111111111 8888811111111111111  \n\
   11111111116888 1111111111111   \n\
    111111111188 1111111111111    \n\
     111111111111111111111111     \n\
      1111111111111111111111      \n\
       11111111111111111111       \n\
        111111111111111111        \n\
          11111111111111          \n\
                1  8              ",

"             1 61  6              \n\
          136 8888888886          \n\
        1 6888888888888886        \n\
       1888886668618 886888       \n\
      1313188 6 881688886888      \n\
     181131 666888 8888888888     \n\
    13881111116888888888888888    \n\
   1388888888688881888888888888   \n\
  1 8888888888 88861 88888888886  \n\
  1888888888881888888 1168888888  \n\
 18888888888883188888111138861881 \n\
 188888888888881888 1111118811381 \n\
  88888888888888  111111118811186 \n\
1 88888888888888868111111118111 81\n\
1188888888888888883111111111111111\n\
11 11 88888888888811111111111116  \n\
1111118888888888811111111111111138\n\
1111118888883888111111111111111188\n\
111111 888868881111111111111111131\n\
111111188888888111111111111111111 \n\
1111111888888 81111111111111111111\n\
 111111888888 8311611111111111111 \n\
 11111188888888116311111111111111 \n\
 111111 8888881118111111111111113 \n\
  11111188888811 811111111111111  \n\
  111111 88881111111111111111113  \n\
   1111116888111111111111111111   \n\
    11111186111111111111111111    \n\
     111111111111111111111111     \n\
      1111111111111111111111      \n\
       11111111111111111111       \n\
        111111111111111111        \n\
          11111111111111          \n\
                 36               ",
"                 3 83             \n\
           1868888888886          \n\
        1 8888888888888886        \n\
       88866883368886888888       \n\
      8 8811 81 888888888888      \n\
      11 88886 68888888888888     \n\
    38111138888888888888888883    \n\
   888 886888668888888888888881   \n\
   88888838888 13888888888888881  \n\
  888888881888888111888888888881  \n\
 68888888818888811111888811886111 \n\
 8888888881 88 111111686111888111 \n\
 8888888888  11111111188111168811 \n\
 888888888886811111111631111118111\n\
3888888888888 111111111 1111111111\n\
 1 88888888881111111111111116811 1\n\
11188888888611111111111111111616  \n\
111888883881111111111111111118 811\n\
1118888888111111111111111111111111\n\
1113888388111111111111111111111611\n\
111188888831111111111111111111111 \n\
 11188888881161111111111111111111 \n\
 11188888831881111111111111111118 \n\
 11168888 118 1111111111111111166 \n\
  111888881181111111111111111168  \n\
  111388811111111111111111111186  \n\
   1116881111111111111111111113   \n\
    111 1111111111111111111111    \n\
     111111111111111111111111     \n\
      1111111111111111111111      \n\
       11111111111111111111       \n\
        111111111111111111        \n\
          11111111111111          \n\
               38  6              ",
"             1 368883             \n\
           8888888888886          \n\
         88888888888888886        \n\
       88686868888888888888       \n\
      8 1 818888868888888888      \n\
     138866188888888888888811     \n\
    1 16888888888888888888861     \n\
   86 6883888888888888888888 11   \n\
  6888 8881 88888888888888888111  \n\
  888818888811168888888888883111  \n\
 68888188886111168881118881 11111 \n\
 888881888111111 8811116888111111 \n\
 88888881111111118811111 88 11 11 \n\
3888888 81111111133111111161113111\n\
8888888881111111111111111111111111\n\
 888888811111111111111116611181111\n\
188888811111111111111111 8 188 111\n\
1888 8 111111111111111111331863161\n\
188688111111111111111111118111111 \n\
188888111111111111111111111661111 \n\
1 88881111111111111111111111111111\n\
  8888 111111111111111111111111 1 \n\
 18888118111111111111111111111886 \n\
 1888811811111111111111111111888  \n\
  188811811111111111111111118888  \n\
  1388111111111111111111111 8886  \n\
   18811111111111111111111188 8   \n\
    11111111111111111111111 11    \n\
     111111111111111111111111     \n\
      1111111111111111111111      \n\
       11111111111111111111       \n\
        111111111111111111        \n\
          11111111111111          \n\
              831 68              ",
"               68888              \n\
          68888888888886          \n\
        688888888888888861        \n\
       886688 6888888888881       \n\
      181888888888888888881       \n\
     88 3888888888888888 3111     \n\
    188888888888888888888116 1    \n\
   1688888888888888888888311111   \n\
  66881 88888888888888888 111111  \n\
  8 8888111888888 88888861111111  \n\
 681888 1111888 1188881 111111111 \n\
 88188 1111188 111  8881118111111 \n\
 8866111111188111111 88111  11111 \n\
3888611111118 111111118111 6111111\n\
888881111111111111116111111 111111\n\
8888111111111111111818111811111111\n\
8886111111111111111 8 138811111111\n\
8 81111111111111111118 188  1  111\n\
88611111111111111111161111  11 811\n\
8681111111111111111111 631111 1811\n\
 881111111111111111111111111111111\n\
 881111111111111111111111111181 1 \n\
 88181111111111111111111111888 11 \n\
 681 11111111111111111111188888 1 \n\
  8 1 11111111111111111188888881  \n\
  631111111111111111111188888881  \n\
   8111111111111111111118888881   \n\
    111111111111111111118 118     \n\
     111111111111111111111111     \n\
      1111111111111111111111      \n\
       11111111111111111111       \n\
        111111111111111111        \n\
          11111111111111          \n\
              3 3688              ",
"              888886              \n\
          68888888888866          \n\
        688888888888888 11        \n\
       88866888888888888 11       \n\
       688688888888888811311      \n\
     38888888888888863 113111     \n\
    88888888888888888111611111    \n\
   8888888888888888888111111111   \n\
  6688888888888888888 1111111111  \n\
  86 118888868888888 11111111111  \n\
 68811138881168881811111111111111 \n\
  8 111686111 1888111161111111111 \n\
  11111 8111111 88 11111111111111 \n\
3  1111181111111161111181111111111\n\
881111111 11111 111111161111111111\n\
881111111111118 311183111111111111\n\
81111111111111 8 1 883   111111111\n\
811111111111111 8118813111  111111\n\
81111111111111116 111111111881 111\n\
61111111111111111 6811111 1 861111\n\
 111111111111111111111111111111111\n\
 1 111111111111111111111186131111 \n\
 16111111111111111111118888181111 \n\
 381111111111111111111888888811 1 \n\
  111111111111111111888888888111  \n\
  11111111111111111 888888888111  \n\
   1111111111111111188888888611   \n\
    11111111111111118611138611    \n\
     111111111111111111111 11     \n\
      1111111111111111111111      \n\
       11111111111111111111       \n\
        111111111111111111        \n\
          11111111111111          \n\
              688368              ",
"              888886              \n\
          6888888888  13          \n\
        6888888888888 1 11        \n\
       88888888888888111111       \n\
      8888888888888 11 11111      \n\
     888888888888 1 1 1111111     \n\
    8888888888888111 111111111    \n\
   8888888888888861111111111111   \n\
   88888888888888111111111111111  \n\
   188886888888 1111111111111111  \n\
 611688118883 1111111111111111111 \n\
 61188111388811116111111111111111 \n\
 1118111 1388 111  11111111111111 \n\
 111811111116111 16111111111111111\n\
 111111111311111113111111111111111\n\
1111111118  1118 11 11111111111111\n\
111111111 8 1388  1111111111111111\n\
1111111111 811881311 8 11111111111\n\
111111111116111111111138811 111111\n\
111111111111 681111111188811 11111\n\
111111111111111111 1111111 11 1111\n\
 111111111111111111168 1611111111 \n\
 11111111111111111388861811111111 \n\
  11111111111111138888888 1111111 \n\
  111111111111188888888888111111  \n\
  111111111111188888888888111111  \n\
   111111111111 888888888811111   \n\
    11111111111188111688811111    \n\
     11111111111111111681111      \n\
      1111111111111111131116      \n\
       111111111111111111 6       \n\
        111111111111111131        \n\
          11111111111111          \n\
              866688              ",
"             3888883              \n\
          68888888111 11          \n\
        6886888886 11 1111        \n\
       88888888886111111111       \n\
      8888888888113111111111      \n\
     888888883131 61111111111     \n\
    88888888811  1111111111111    \n\
   8888888888111111111111111111   \n\
  6888888888 1111111111111111111  \n\
  8888888861 1111111111111111111  \n\
 18816881611111111111111111111111 \n\
 1811688 111811111111111111111111 \n\
 1 111688111611111111111111111111 \n\
1111111611111611111111111111111111\n\
1111111111111611111111111111111111\n\
11111681116 1111111111111111111111\n\
11111131 88    1111111111111111111\n\
1111118 18816  18 1111111111111111\n\
111111181111 1111688 1111111111111\n\
111111116811111111888111 111111111\n\
1111111111111 1111111 111 11111111\n\
 1111111111111138 181111111111111 \n\
 1111111111113888618 111111111111 \n\
 11111111111 88888888111111111111 \n\
  111111111888888888881111111111  \n\
  111111111888888888881111111111  \n\
   1111111188888888888111111111   \n\
    1111111186111688831111 111    \n\
     111111111111168611111611     \n\
      1111111111111 11111811      \n\
       1111111111111111 8 1       \n\
        11111111111111 311        \n\
          11111111111111          \n\
              8686  1             ",
"             388883               \n\
          68888 11 11116          \n\
        6688888611 1111111        \n\
       88888881111111111111       \n\
      8888881111111111111111      \n\
     8888 1611611111111111111     \n\
    8888811  11111111111111111    \n\
   8888881111111111111111111111   \n\
  688888 11111111111111111111111  \n\
  88888 311111111111111111111111  \n\
 61881111111111111111111111111111 \n\
  188111 111111111111111111111111 \n\
 11881113111111111111111111111111 \n\
 111 11 61111111111111111111111111\n\
 1111111  111111111111111111111111\n\
11  118111111111111111111111111111\n\
11 318811 111111111111111111111111\n\
111618 61116 111111111111111111111\n\
111311131111688 111111111111111111\n\
1111 81111111888111 11111111111111\n\
1111111111111111 11111111111111111\n\
 111111111 8318111111111111111111 \n\
 1111111 888818311111111111111111 \n\
 11111118888888811111111111111111 \n\
  111118888888888111111111111111  \n\
  111118888888888811111111111111  \n\
   1111188888888881111111111111   \n\
    11116 1113888 111111111111    \n\
     111111111688111111811111     \n\
      1111111111811111811111      \n\
       11111111111111831111       \n\
        1111111111118 1111        \n\
          11111111111111          \n\
              883 111             ",
"              686331              \n\
          688 1161111163          \n\
        6888611 1111111111        \n\
       88881111111111111111       \n\
      8881111111111111111111      \n\
     83111 111111111111111111     \n\
    8811  11111111111111111111    \n\
   8881111111111111111111111111   \n\
  688 11111111111111111111111111  \n\
  88 111111111111111111111111111  \n\
 36111111111111111111111111111111 \n\
 811 1111111111111111111111111111 \n\
 811 11111 1111111111111111111111 \n\
1611 11111111111111111111111111111\n\
1111 11111111111111111111111111111\n\
 11 111111111111111111111111111111\n\
 18 131111111111111111111111111111\n\
1181  3  1111111111111111111111111\n\
1 11 11 88 1 111111111111111111111\n\
116111 1 86111 1111111111111111111\n\
11111111111 1111111111111111111111\n\
 1111 681 11111111111111111111111 \n\
 11118886 81111111111 11111111111 \n\
 11168888883111111111111111111111 \n\
  118888888861111111111111111111  \n\
  11688888888 111111111111111111  \n\
   1188888888811111111111111111   \n\
    11111688811111111111111111    \n\
     11111188111111 111111111     \n\
      1111118111111 11111111      \n\
       11111111116881111111       \n\
        111111111881111111        \n\
          11111111111111          \n\
              8 11111             ",
"              333 13              \n\
          63   11111   6          \n\
        68 1 1111111111116        \n\
       88 1111111111111111        \n\
      83 111111111111111111       \n\
      11111111111111111111113     \n\
      311111111111111111111113    \n\
   8111111111111111111111111113   \n\
  611111111111111111111111111116  \n\
  611111111111111111111111111111  \n\
 31111111111111111111111311111111 \n\
 11111111111111111111111111111111 \n\
 11111111111111111111111111111111 \n\
 111111111111111111111111111111111\n\
1111111111111111111111111111111111\n\
1111111111111111111111111111111111\n\
61 1111111111111111111111111111111\n\
33 6 11111111111111111111111111111\n\
1 11881111111111111111111111111111\n\
1111 8611 111111111111111111111111\n\
1111111 11111111111111111111111111\n\
 118  111111111111111111111111111 \n\
 16881811111111111111111111111111 \n\
 18888831111111111111111111111111 \n\
  888888 11111111111111111111111  \n\
  188888811111111111111111111111  \n\
    868886111111111111111111111   \n\
    11 88 11111111111111111111    \n\
     1113 11111 1111111111111     \n\
      1116111116111111111111      \n\
       111111188 1111111111       \n\
        111111881111111111        \n\
          11111111111111          \n\
               111111             ",
"                186               \n\
          6  11111611683          \n\
         13111111111111 86        \n\
        1111111111111111188       \n\
      1111111111111111111188      \n\
     111111111111111111111 88     \n\
     1111111111111111111111 88    \n\
    111111111111111111111111188   \n\
  111111111111111111111111111 8   \n\
  1111111111111111111111111111 8  \n\
 111111111111111111111111111111 1 \n\
 11111111111111111111111111111116 \n\
 11111111111111111111111111111111 \n\
 111111111111111111111111111111111\n\
 111111111111111111111111111111111\n\
1111111111111111111111111111111111\n\
 111111111111111111111111111111111\n\
1 11111111111111111111111111111111\n\
1 81111111111111111111111111111111\n\
 1611 1111111111111111111111111111\n\
1111111111111111111111111111111111\n\
 81111111111111111111111111111111 \n\
 8661111 111111111111111111111111 \n\
 68811111111111111111111111111111 \n\
  888111111111111111111111111111  \n\
  688811111111111111111111111111  \n\
   1881111111111111111111111111   \n\
    18 11111111111111111111111    \n\
     111111111111111111111111     \n\
      11111 1111111111111111      \n\
       11118811111111111111       \n\
        111 81111111111111        \n\
          11111111111111          \n\
               111111             ",
"              1 88  3             \n\
            111  1168886          \n\
        111111111111168886        \n\
       111111111111111 8888       \n\
      1111111111111111188888      \n\
     111111111111111111188888     \n\
    11111111111111111111188888    \n\
   1111111111111111111111118888   \n\
  1111111111111111111111111 8811  \n\
  111111111111111111111111111811  \n\
 111111111111111111111111111188   \n\
 11111111111111111111111111111681 \n\
 11111111111111111111111111111118 \n\
1111111111111111111111111111111111\n\
111111111111111111111111111111111 \n\
1111111111111111111111111111111111\n\
1111111111111111111111111111111111\n\
111111111111111111111111111111111 \n\
811111111111111111111111111111111 \n\
 1 111111111111111111111111111111 \n\
111 11111111111111111111111111111 \n\
 11111111111111111111111111111111 \n\
 611111 1111111111111111111111111 \n\
 611 1111111111111111111111111111 \n\
  811111111111111111111111111111  \n\
  661111111111111111111111111111  \n\
   8111111111111111111111111111   \n\
     1111111111111111111111111    \n\
     111111111111111111111111     \n\
      11  111111111111111111      \n\
       118 1111111111111111       \n\
        1  111111111111111        \n\
          11111111111111          \n\
              1111111             ",
"             13636 3              \n\
           11611 8888881          \n\
        111111111138888883        \n\
       111111111111 8888868       \n\
      1111111111111188888868      \n\
     111111111111111888888888     \n\
    11111111111111111 8888888     \n\
   1111111111111111111 688888 1   \n\
  111111111111111111111 68811111  \n\
  1111111111111111111111  8 1111  \n\
 111111111 11111111111111188181 1 \n\
 11111111111111111111111111 8811  \n\
 1111111111111111111111111111 811 \n\
1111111111111111111111111111111131\n\
1111111111111111111111111111111186\n\
1111111111111111111111111111111168\n\
1111111111111111111111111111111188\n\
1111111111111111111111111111111188\n\
1111111111111111111111111111111188\n\
1111111111111111111111111111111188\n\
111111111111111111111111111111118 \n\
 11111111111111111111111111111118 \n\
 11111111111111111111111111111118 \n\
 1 111111111111111111111111111116 \n\
  111111111111111111111111111111  \n\
   11111111111111111111111111116  \n\
   1111111111111111111111111113   \n\
    1 111111111111111111111111    \n\
     161111111111111111111111     \n\
      6311111111111111111111      \n\
       6 111111111111111111       \n\
         11111111111111111        \n\
          11111111111111          \n\
             11111111             ",
"               36363              \n\
          18 1688888811           \n\
        1111111 888888868         \n\
       1111111118888888 88        \n\
      111111111188888888 38       \n\
     111111111113888888888811     \n\
    11111111111111888888888111    \n\
   111111111111111 888883 61111   \n\
  111111111111111116888611161111  \n\
  1111111111111111111688111  111  \n\
 11111 1111111111111118811611 111 \n\
 11111111111111111111111886111111 \n\
 1111111111111111111111111 811111 \n\
111111111111111111111111111 118111\n\
11111111111111111111111111111 8861\n\
1111111111111111111111111111118881\n\
1111111111111111111111111111188883\n\
1111111111111111111111111111188888\n\
1111111111111111111111111111188888\n\
1111111111111111111111111111188888\n\
111111111111111111111111111118888 \n\
 1111111111111111111111111111 888 \n\
 11111111111111111111111111111888 \n\
 11111111111111111111111111111883 \n\
  111111111111111111111111111188  \n\
  11111111111111111111111111188   \n\
   1111111111111111111111111186   \n\
    11111111111111111111111186    \n\
     11111111111111111111118      \n\
      11111111111111111111 1      \n\
       111111111111111111 1       \n\
        111111111111111111        \n\
          11111111111111          \n\
             11111111             ",
"                3 33              \n\
          6 888888611611          \n\
        1111188888888 8831        \n\
       11111188888888688811       \n\
      1111118888888886186111      \n\
     1111111 8888888888811111     \n\
    111111111 8888888888111111    \n\
   11111111111 88888   61111111   \n\
  111111111111118881111111111111  \n\
  111111111111111881111  1111111  \n\
 11 11111111111116881  11  111111 \n\
 11111111111111111163811111111111 \n\
 11111111111111111111138111111111 \n\
1111111111111111111111161166 31111\n\
1111111111111111111111111188881111\n\
1111111111111111111111111188888611\n\
1111111111111111111111111 88888811\n\
1111111111111111111111111888888881\n\
111111111111111111111111188888888 \n\
111111111111111111111111188888888 \n\
1111111111111111111111111188888881\n\
 11111111111111111111111118888888 \n\
 11111111111111111111111111888881 \n\
 11111111111111111111111111888881 \n\
  111111111111111111111111188881  \n\
  111111111111111111111111 88811  \n\
   11111111111111111111111888 1   \n\
    111111111111111111111 8811    \n\
     111111111111111111118611     \n\
      1111111111111111118311      \n\
       11111111111111116611       \n\
        111111111111111 11        \n\
          11111111111111          \n\
             1111111              ",
"              3363                \n\
          688888 118 111          \n\
        118888888888888111        \n\
       11188888886 888 1 11       \n\
      111888888886868 111111      \n\
     111188888888888611111111     \n\
    11111 88888888881111111111    \n\
   1111111188883 11311111111111   \n\
  11111111388881111 111111111111  \n\
  111111111188311113 11111111111  \n\
 1111111111168811811116111111111  \n\
 1111111111111638 111111111111111 \n\
 1111111111111111 811111111111111 \n\
111111111111111111611188661111111 \n\
1111111111111111111118888881111111\n\
1111111111111111111116888888611111\n\
1111111111111111111118888888811111\n\
1111111111111111111188888888881111\n\
1111111111111111111188888888888311\n\
11111111111111111111 8888888888811\n\
1111111111111111111118888888888111\n\
 11111111111111111111 88888888811 \n\
 11111111111111111111136888888811 \n\
 11111111111111111111118888888111 \n\
  111111111111111111111888886111  \n\
  111111111111111111111888881111  \n\
   1111111111111111111688881111   \n\
    11111111111111111188861111    \n\
     111111111111111168861111     \n\
      1111111111111118811111      \n\
       11111111111116811111       \n\
        111111111111811111        \n\
          11111111111111          \n\
             111111 1             ",
"             3  161 3             \n\
          638811 8311 11          \n\
         88888888888311111        \n\
       1888888  88861 11111       \n\
      1888888886881111111111      \n\
     168888888888111111111111     \n\
    11888888888811111111111111    \n\
   11138888 81 1111111111111111   \n\
  1111 688 1111  111111111111116  \n\
  111111881111331111111111111118  \n\
 11111118811811111611111111111166 \n\
 11111111 68611111111 11111111118 \n\
 11111111111381111111111111111118 \n\
11111111111116111886 611111111116 \n\
1111111111111111888888 11111111118\n\
111111111111111188888888611111111 \n\
1111111111111111888888888111111111\n\
1111111111111118888888888831111111\n\
1111111111111118888888888888 11111\n\
1111111111111113888888888888811111\n\
1111111111111111888888888888311111\n\
 111111111111111 8888888888811111 \n\
 1111111111111111 888888888611111 \n\
 11111111111111111 88888888111111 \n\
  1111111111111111 8888888 11111  \n\
  111111111111111188888831111111  \n\
   1111111111111118888861111111   \n\
    11111111111111888881111111    \n\
     111111111111 88861111111     \n\
      1111111111168 11111111      \n\
       11111111118611111111       \n\
        111111111811111111        \n\
          11111111111111          \n\
             1111 111             ",
"               3 188              \n\
          68118631111111          \n\
        68888688631111111         \n\
       888  8886 1111111111       \n\
      88883368 1111111111111      \n\
     888888881111111111111118     \n\
    6888888611111111111111111     \n\
   1388881811111111111111111188   \n\
  116881111111111111111111111886  \n\
  11181111 111111111111111111888  \n\
 111 8116111181111111111111118886 \n\
 111136811111111 1111111111118888 \n\
 11111118 1111111111111111111 888 \n\
111111111 11886361111111111111888 \n\
11111111111888888 1111111111111888\n\
1111111111188888888811111111111 16\n\
1111111111 888888888 111111111111 \n\
111111111188888888888811111111111 \n\
111111111188888888888888 111111111\n\
1111111111 8888888888888811111111 \n\
111111111118888888888888611111111 \n\
 11111111111888888888888111111111 \n\
 11111111111188888888888111111111 \n\
 1111111111111888888888111111111  \n\
  11111111111188888888 111111111  \n\
  111111111111888888 11111111111  \n\
   1111111111188888811111111111   \n\
    11111111118888811111111111    \n\
     11111111 888811111111111     \n\
      1111111188111111111111      \n\
       111111 8111111111111       \n\
        111111611111111111        \n\
          11111111111111          \n\
             11131111             ",
"              3188 11             \n\
           18 111111111           \n\
        68 8886111111116          \n\
       83888631111111111168       \n\
      888681111111111111168       \n\
     8888 111111111111111 811     \n\
    8888 11111111111111111 881    \n\
   8886111111111111111111168881   \n\
   81111111111111111111111888886  \n\
   611 111111111111111111 888888  \n\
 181 11  111111111111111118888886 \n\
 1 881111111111111111111118888888 \n\
 1116 111111111111111111118888888 \n\
1111111336 611111111111111 888888 \n\
111111 88888 111111111111116888886\n\
1111111888888881111111111111 11 88\n\
1111118888888881111111111111111188\n\
1111118888888888811111111111111188\n\
1111118888888888888 11111111111188\n\
11111168888888888888 1111111111186\n\
111111188888888888881111111111118 \n\
 11111168888888888811111111111118 \n\
 1111111 8888888888111111111111 8 \n\
 11111111888888888611111111111166 \n\
  111111138888888611111111111118  \n\
  1111111 8888881111111111111116  \n\
   1111111888886111111111111113   \n\
    11111188888111111111111111    \n\
     111118888111111111111111     \n\
      1111 81111111111111111      \n\
       11118111111111111111       \n\
        1111 1111111111111        \n\
          11111111111111          \n\
             1311111              ",
"             1 831111             \n\
            11111111116           \n\
        6886111111111 3336        \n\
       888  111111111118888       \n\
      361111111111111188   8      \n\
     88111111111111111381111      \n\
    88111111111111111118888111    \n\
   8811111111111111111188888 61   \n\
  61 111111111111111118888888886  \n\
  111111111111111111188888888886  \n\
 66161111111111111111688888888883 \n\
 81111111111111111111688888888886 \n\
 16111111111111111111688888888888 \n\
11 18 311111111111111188888888888 \n\
1118888811111111111111188888888868\n\
111888888811111111111111 111388888\n\
1118888888111111111111111111188888\n\
1118888888861111111111111111188868\n\
11188888888888111111111111111 883 \n\
1118888888888881111111111111118861\n\
111388888888888111111111111111888 \n\
 11188888888881111111111111118888 \n\
 1111888888888111111111111111888  \n\
 1111188888888111111111111111888  \n\
  11118888888111111111111111188   \n\
  111188888 11111111111111111881  \n\
   1116888811111111111111111181   \n\
    11188681111111111111111111    \n\
     118881111111111111111111     \n\
      118 111111111111111111      \n\
       11611111111111111111       \n\
        11 111111111111111        \n\
          11111111111111          \n\
              11111               ",
"              8 1111              \n\
           1111111 13836          \n\
        681111111116188886        \n\
       6  11111111118888888       \n\
       11111111111 881 6 816      \n\
     111111111111118 111 1 88     \n\
    111111111111111888881111 8    \n\
   1111111111111113888888886388   \n\
  111111111111111888888888888 86  \n\
  11111111111111 888888888888868  \n\
 111111111111111 888888888888838  \n\
 111111111111111 8888888888888818 \n\
 11111111111111138888888888888886 \n\
11  111111111111188888888888888811\n\
1888111111111111118888888888888881\n\
1888861111111111111 1111 888888681\n\
1888881111111111111111111888888861\n\
1888888 11111111111111111888888811\n\
188888888 111111111111111188888811\n\
1888888888 11111111111111188886811\n\
1688888888111111111111111188888811\n\
 18888888111111111111111118888883 \n\
 1 888888 11111111111111188888811 \n\
 118888881111111111111111 8888131 \n\
  188888 11111111111111111888811  \n\
  11888811111111111111111 888111  \n\
   1888611111111111111111188111   \n\
    186111111111111111111  111    \n\
     661111111111111111111111     \n\
      6111111111111111111111      \n\
       81111111111111111111       \n\
        111111111111111111        \n\
          11111111111111          \n\
             111111               "],
      currentSentenceIndex: 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.cycleSentences, 100); // Change sentences every 1 second (adjust as needed)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  cycleSentences = () => {
    this.setState((prevState) => ({
      currentSentenceIndex:
        (prevState.currentSentenceIndex + 1) % prevState.sentences.length,
    }));
  };

  render() {
    const { sentences, currentSentenceIndex } = this.state;
  
    return <p className="fixed-width-text">{sentences[currentSentenceIndex]}</p>;
  }
}

export default SentenceCycler;
